define("discourse/plugins/badges-below-avatar/discourse/initializers/badges-below-avatar", ["exports", "discourse/lib/plugin-api", "virtual-dom"], function (_exports, _pluginApi, _virtualDom) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: 'badges-below-avatar',
    initialize(container) {
      const user = container.lookup('current-user:main');
      const siteSettings = container.lookup("site-settings:main");
      if (siteSettings.badges_below_avatar_enabled) {
        (0, _pluginApi.withPluginApi)('0.8.23', api => {
          api.includePostAttributes('featured_user_badges', 'user_badge_count');
          api.decorateWidget("post-avatar:after", helper => {
            const post = helper.getModel();
            if (post.featured_user_badges) {
              return (0, _virtualDom.h)('div.post-badge-list', helper.attach('user-badge-list', {
                username: post.username,
                badgeList: post.featured_user_badges,
                userBadgeCount: post.user_badge_count
              }));
            }
          });
        });
      }
    }
  };
});