define("discourse/plugins/badges-below-avatar/discourse/widgets/user-badge", ["discourse/widgets/widget", "discourse-common/lib/icon-library", "virtual-dom", "discourse/lib/url", "discourse-common/lib/get-url", "@ember/object/computed"], function (_widget, _iconLibrary, _virtualDom, _url, _getUrl, _computed) {
  "use strict";

  (0, _widget.createWidget)('user-badge', {
    tagName: 'span.user-badge',
    buildClasses(attrs) {
      let classes = '';
      classes += "badge-type-" + attrs.badgeType.name.toLowerCase();
      if (!attrs.badge.enabled) {
        classes += " disabled";
      }
      classes += "";
      return classes;
    },
    buildAttributes(attrs) {
      let title = $("<div>" + attrs.badge.description + "</div>").text();
      return {
        "data-badge-name": attrs.badge.name,
        title
      };
    },
    html(attrs) {
      const {
        badge
      } = attrs;
      let contents = [];
      if (badge.icon) {
        contents.push((0, _iconLibrary.iconNode)(badge.icon.split("-")[1]));
      } else if (badge.image_url) {
        contents.push((0, _virtualDom.h)('img', {
          attributes: {
            src: badge.image_url
          }
        }));
      }
      contents.push((0, _virtualDom.h)('span.badge-display-name', badge.name));
      return contents;
    }
  });
  (0, _widget.createWidget)('user-badge-list-item', {
    tagName: 'li',
    html(attrs) {
      const {
        badge,
        badgeType,
        username
      } = attrs;
      let badgeUrl = (0, _getUrl.default)(`/badges/${badge.id}/${badge.slug}`);
      let userBadgeUrl = badgeUrl + "?username=" + username;
      return (0, _virtualDom.h)('a', {
        attributes: {
          href: userBadgeUrl
        }
      }, this.attach('user-badge', {
        badge,
        badgeType
      }));
    }
  });
  (0, _widget.createWidget)('user-badge-list', {
    tagName: 'ul.user-badge-list',
    html(attrs) {
      const {
        username,
        badgeList,
        userBadgeCount
      } = attrs;
      let contents = [];
      if (badgeList && badgeList.badges) {
        badgeList.badges.forEach(badge => {
          let badgeType = badgeList.badge_types.find(bt => bt.id === badge.badge_type_id);
          contents.push(this.attach('user-badge-list-item', {
            badge,
            badgeType,
            username
          }));
        });
        let moreBadgesCount = userBadgeCount - badgeList.badges.length;
        if (moreBadgesCount > 0) {
          contents.push((0, _virtualDom.h)('li', this.attach('link', {
            href: (0, _url.userPath)(username + '/badges'),
            labelCount: 'badges.more_badges',
            count: moreBadgesCount,
            title: 'badges.more_badges',
            titleOptions: {
              count: moreBadgesCount
            },
            className: 'btn more-user-badges'
          })));
        }
      }
      return contents;
    }
  });
});